import axios from "axios";
import { setAlert } from "./alert";
import { LOGIN_SUCCESS, LOGIN_FAIL, USER_LOADED, AUTH_ERROR, LOGOUT } from "./types";
import setAuthToken from "../utils/setAuthToken";
import { toast } from "react-toastify";

//Load user
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get("/api/auth");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//Login
export const login =
  ({ login, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ login, password });
    try {
      const res = await axios.post("/api/auth", body, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      toast.success("Pomyślnie zalogowano jako administrator.");
      dispatch(loadUser());
    } catch (error) {
      toast.error("Wystąpił błąd - sprawdź poprawność danych.");
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

export const logout = () => (dispatch) => {
  if (localStorage.token) {
    localStorage.removeItem("token");
  }
  toast.info("Pomyślnie wylogowano.");
  dispatch({
    type: LOGOUT,
  });
};
