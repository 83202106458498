export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const GET_ALL_ITEMS = "GET_ALL_ITEMS";
export const DELETE_ITEM = "DELETE_ITEM";
export const ITEM_ERROR = "ITEM_ERROR";
export const ADD_ITEM = "ADD_ITEM";
export const GET_ITEM = "GET_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const GET_DATE = "GET_DATE";
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SWAP_UP = "SWAP_UP";
export const SWAP_DOWN = "SWAP_DOWN";
